<template>
  <v-select
    v-if="edit"
    v-model="model"
    :options="heatingOptions"
    :clearable="false"
    :reduce="(t) => t.name"
    label="label"
    @input="onInput"
  />
  <span v-else class="heating-type-picker">{{ selectedLabel }}</span>
</template>

<script>
export default {
  props: {
    portfolio: {
      type: Object,
      required: true,
    },
    value: {
      type: String,
    },
    exclude: {
      type: Array,
    },
    edit: {
      type: Boolean,
    },
  },

  data() {
    return {
      model: null,
    }
  },

  computed: {
    heatingOptions() {
      const options = []
      for (const heatingType in this.portfolio.heating_types) {
        options.push({
          id: this.portfolio.heating_types[heatingType].id,
          name: this.portfolio.heating_types[heatingType].name,
          label: this.portfolio.getHeatingTypeName((k) => this.$t(k), heatingType),
        })
      }
      return options.filter((o) => !this.exclude || !this.exclude.includes(o.name))
    },

    selectedLabel() {
      return this.getSelectedLabel()
    },
  },

  watch: {
    value() {
      this.model = this.value
    },
    selectedLabel() {
      return this.getSelectedLabel()
    },
  },

  created() {
    this.model = this.value
  },

  methods: {
    onInput() {
      this.$emit('input', this.model)
    },

    getSelectedLabel() {
      return this.heatingOptions.find((o) => o.name === this.model)
        ? this.heatingOptions.find((o) => o.name === this.model).label
        : this.$t(`_heatingTypes.UNKNOWN`)
    },
  },
}
</script>

<style>
.heating-type-picker {
  margin-right: var(--spacing-xs);
}
</style>
