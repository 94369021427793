<i18n>
{
  "de": {
    "emptyErrorMessage": "Wert darf nicht leer sein",
    "notANumberErrorMessage": "{text} ist keine Zahl",
    "notAnIntegerErrorMessage": "Wert muss eine Ganzzahl sein",
    "tooSmallErrorMessage": "Wert darf nicht kleiner als {min} sein",
    "tooLargeErrorMessage": "Wert darf nicht grösser als {max} sein"
  }
}
</i18n>

<template>
  <span class="numeric-input" :class="classes">
    <template v-if="edit">
      <div class="input">
        <input
          ref="input"
          type="text"
          :value="value"
          @input="updateValue($event.target.value)"
          @change="updateValue($event.target.value)"
          @focus="focus"
        />
        <div v-if="units" class="units">
          {{ units }}
        </div>
      </div>
      <div v-if="validationError" class="validation-error">{{ validationError }}</div>
    </template>

    <span v-else-if="value !== null && value !== undefined" class="read-only-value">
      <span v-if="noFormatting">{{ value }}</span>
      <span v-else>{{ formatNumber(value) }}</span> <span v-if="units">{{ units }}</span>
    </span>

    <span v-else class="read-only-value">{{ emptyValue }}</span>
  </span>
</template>

<script>
export default {
  props: {
    value: {},
    int: {
      type: Boolean,
      default: false,
    },
    optional: {
      type: Boolean,
      default: false,
    },
    noFormatting: {
      type: Boolean,
      default: false,
    },
    min: {
      type: Number,
    },
    max: {
      type: Number,
    },
    units: {
      type: String,
    },
    emptyValue: {
      type: String,
      default: '-',
    },
    invalidValues: {
      type: Array,
    },
    invalidValuesErrorMessage: {
      type: String,
    },
    edit: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    validationError() {
      return this.getValidationError(this.value)
    },

    classes() {
      return {
        invalid: Boolean(this.validationError),
      }
    },
  },

  // Reset validation when edit mode is toggled
  watch: {
    edit() {
      this.$emit('validation', !this.validationError)
    },
  },

  created() {
    if (this.edit) {
      this.$emit('validation', !this.validationError)
    }
  },

  methods: {
    getValidationError(value) {
      if (value === undefined || value === null || value === '') {
        if (!this.optional) {
          return this.$t('emptyErrorMessage')
        }
      } else if (Number.isNaN(Number(value))) {
        return this.$t('notANumberErrorMessage', { text: value })
      } else if (this.int && !Number.isInteger(Number(value))) {
        return this.$t('notAnIntegerErrorMessage')
      } else if (this.min !== undefined && value < this.min) {
        return this.$t('tooSmallErrorMessage', { min: this.min })
      } else if (this.max !== undefined && value > this.max) {
        return this.$t('tooLargeErrorMessage', { max: this.max })
      } else if (this.invalidValues && this.invalidValues.includes(value)) {
        return this.invalidValuesErrorMessage
      }
      return null
    },

    updateValue(value) {
      // Convert string to number
      if (!Number.isNaN(Number(value)) && value !== '') {
        value = Number(value)
      }
      if (value === undefined || value === null || value === '') {
        value = null
      }
      this.$emit('input', value)
      this.$emit('validation', !this.getValidationError(value))
    },

    focus() {
      this.$refs.input.select()
    },
  },
}
</script>

<style lang="scss">
.numeric-input {
  --box-padding-h: var(--spacing-xs);
  --box-padding-v: var(--spacing-xs);
  --box-padding: var(--box-padding-v) var(--box-padding-h);
  display: flex;
  flex-direction: column;
  width: 100%;

  & .input {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: center;
    max-width: 100%;
  }

  & input {
    border-color: var(--hairline-color);
    width: 100% !important;
    overflow: visible;
    text-overflow: initial;

    &:focus {
      border-color: var(--secondary-highlight-color);
    }
  }

  &.invalid {
    & .input,
    & input,
    & .validation-error {
      color: var(--error-color) !important;
    }

    & .input input {
      border: var(--box-error-border) !important;
    }

    & .validation-error {
      display: block;
      font-weight: 400;
      font-size: var(--font-xs);
      line-height: 16px;
    }
  }

  & .units {
    position: absolute;
    pointer-events: none;
    right: 1px;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 var(--box-padding-h);
    background-color: #fcfcfc;
    color: #777;
    border-left: var(--box-border);
    border-radius: 0 calc(var(--box-radius) - 1px) calc(var(--box-radius) - 1px) 0;
    height: calc(100% - 2px);
    display: flex;
    align-items: center;
    margin: auto;
    margin-left: var(--spacing-xs);
  }
}
</style>
