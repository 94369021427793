<template>
  <div class="two-column-row">
    <div class="form-label">
      <div class="form-label__label">
        <span class="label" :title="label">
          {{ label }}
        </span>
        <InfoBox v-if="infoText" :title="infoTitle ? infoTitle : label">
          <div v-html="infoText"></div>
        </InfoBox>
      </div>
      <div v-if="sublabel" class="form-label__sublabel">
        <span class="sublabel" :title="sublabel">
          {{ sublabel }}
        </span>
      </div>
    </div>
    <div class="form-input">
      <slot></slot>
    </div>
  </div>
</template>

<script>
import InfoBox from '@/components/shared/InfoBox.vue'

export default {
  components: {
    InfoBox,
  },

  props: {
    label: {
      type: String,
    },
    sublabel: {
      type: String,
      required: false,
    },
    infoTitle: {
      type: String,
    },
    infoText: {
      type: String,
    },
  },
}
</script>

<style lang="scss" scoped>
.form-label {
  display: flex;
  flex-direction: column;

  & .form-label__label {
    font-weight: 500;
    line-height: 32px;
    color: var(--secondary-highlight-color);
    display: flex;
    flex-direction: row;
    min-height: 32px;
    align-items: center;

    & .label {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    & .label + * {
      margin-left: var(--spacing-xs);
    }
  }

  & .form-label__sublabel {
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: var(--secondary-text-color);
  }
}

.form-input {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 4px;
  align-items: center;
  line-height: 32px;
}

.two-column-row {
  display: grid;
  grid-template-columns: 200px 1fr;
  align-items: start;
  justify-content: start;
  gap: var(--spacing-m);
  column-gap: var(--spacing-l);
  margin-bottom: var(--spacing-m);
}
</style>
